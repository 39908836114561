<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import { Bar } from "vue-chartjs";
import BarChartRecette from "./BarChartRecette";
import BarChartFrais from "./BarChartFrais";
//import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";
import Multiselect from "vue-multiselect";

//const currentYear = new Date().getFullYear();
// const year = [];
// for (let i = 2021; i < 2031; i++) {
//   year.push({ name: i.toString(), value: i.toString() });

// }

export default {
  //extends: Bar,
  page: {
    title: "Form Validation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    BarChartRecette,
    BarChartFrais,
    Multiselect,
  },
  data() {
    return {
      title: "Suivi ges gains",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Historistique",
          active: true,
        },
      ],
      order: [
        {
          userid: "ZANSOUKPE Euphrem",
          total: "12 500 XOF",
          date: "12 Mar,2020",
        },
      ],
      ordersData: [],
      showPassword: false,
      phoneNumber: "",
      thenDay: "",
      range: {
        minlen: "",
        maxlength: "",
        between: "",
        minval: "",
        maxval: "",
        rangeval: "",
        expr: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      gain: {
        totalRecette: 0,
        totalFrais: 0,
      },
      year:[],
      yearvalue: { name: "2022", value: 2022 },
      yearvalueR: { name: "2022", value: 2022 },
      totalRecette: 0,
      totalFrais: 0,
      today: new Date(),
    };
  },

  async mounted() {
    //Chargement des données
    this.actualYear = this.today.getFullYear();
    this.yearvalue.name = this.actualYear
    this.yearvalue.value = this.actualYear
    this.yearvalueR.name = this.actualYear
    this.yearvalueR.value = this.actualYear
 
  
  
    this.init();
    
  },

  methods: {
    async init() {
      const gains = await apiRequest(
        "GET",
        "admin/gain/info",
        undefined,
        false
      );

      if (gains && gains.data) {
        let data = gains.data;
       
        this.gain.totalRecette = gains.data.totalRecette
          ? gains.data.totalRecette
          : "0";
        this.gain.totalFrais = gains.data.totalFrais
          ? gains.data.totalFrais
          : "0";

        data.annees.forEach((element) => {
          this.year.push({ name: element, value: element });
        });

        data.gains.forEach((element) => {
          if(element.annee == this.yearvalue.value){
            this.totalRecette+=element.recette;
          }
        });

        data.gains.forEach((element) => {
          if(element.annee == this.yearvalueR.value){
            this.totalFrais+=element.frais;
          }
        });
      }
    },
    async recette(val){
      this.totalRecette=0;
      const gains = await apiRequest(
        "GET",
        "admin/gain/info",
        undefined,
        false
      );

      if (gains && gains.data) {
        let data = gains.data;

        data.gains.forEach((element) => {
          if(element.annee == val.value){
            this.totalRecette+=element.recette;
          }
        });
      }
    },
    async frais(val){
      this.totalFrais=0;
      const gains = await apiRequest(
        "GET",
        "admin/gain/info",
        undefined,
        false
      );

      if (gains && gains.data) {
        let data = gains.data;
    
        data.gains.forEach((element) => {
          if(element.annee == val.value){
            this.totalFrais+=element.frais;
          }
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      //console.log("this.$v:", this.$v);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
   
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars

  },

  watch: {
    yearvalue: {
      handler(val) {
        this.recette(val);
      },
    },
    yearvalueR: {
      handler(val) {
        this.frais(val);
      },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5></h5>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">
                      Total des recettes
                    </h5>
                  </template>
                  <div class=" float-right">
                    <h1 class="text-primary" style="font-size:30px;">
                      {{ this.gain.totalRecette }} XOF
                    </h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-success"
                  class="border border-success"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">
                      Total des frais de réservation collectés
                    </h5>
                  </template>
                  <div class=" float-right">
                    <h1 class="text-primary" style="font-size:30px;">
                      {{ this.gain.totalFrais }} XOF
                    </h1>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body mt-3">
          <b-tabs pills justified content-class="p-3 text-muted">
            <b-tab active class="border-0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  ><i class="mdi mdi-cash mr-2"></i>Gains</span
                >
              </template>
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <h4 class="col-md-6 card-title mb-4">
                        Total des recettes
                      </h4>
                    </div>
                    <div class="row mb-4" style="align-items: center;">
                      <div class="col-md-4"></div>
                      <div class="col-md-2">
                        <multiselect
                        style="text-align:center;"
                          v-model="yearvalue"
                          :options="year"
                          label="name"
                          track-by="name"
                          :close-on-select="true"
                          :show-labels="false"
                          :allow-empty="false"
                        ></multiselect>
                      </div>
                      <div class="col-md-4" style="font-size: 18px; font-weight: bold;"> Total {{ yearvalue.value }} : {{ totalRecette }} </div>
                      <div class="col-md-2"></div>
                    </div>
                    <!-- Bar Chart -->
                    <BarChartRecette :height="300" :annee="yearvalue" />
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <h4 class=" col-md-6 card-title mb-4">
                        Aperçu des frais de réservations
                      </h4>
                    </div>
                    <div class="row mb-4" style="align-items: center;">
                      <div class="col-md-4"></div>
                      <div class="col-md-2">
                        <multiselect
                          style="text-align:center;"
                          v-model="yearvalueR"
                          :options="year"
                          label="name"
                          track-by="name"
                          :close-on-select="true"
                          :show-labels="false"
                          placeholder=""
                          :allow-empty="false"
                        ></multiselect>
                      </div>
                      <div class="col-md-4" style="font-size: 18px; font-weight: bold;"> Total {{ yearvalueR.value }} : {{ totalFrais }} </div>
                      <div class="col-md-2"></div>
                    </div>
                    <!-- Bar Chart -->
                    <BarChartFrais :height="300" :annee="yearvalueR" />
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35!important;
  border-bottom: 1px solid #000000!important;
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000!important;
}


.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color:#3AAA35;
}

option{
  background-color:#3AAA35 !important;
}

</style>
