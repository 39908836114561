<script>
import { Bar } from "vue-chartjs";
import { apiRequest } from "@/helpers/api-call";

export default {
  props: {
    annee: {
      type: Object,
      default: null,
    },
  },
  extends: Bar,
  data() {
    return {
      dataGain: null,
      dataRecette: [],
      mois: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      dataMois: [],
    };
  },
  watch: {
    annee: {
      handler(val) {
        console.log("val:", val);
        this.loadData();
      },
    },
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.dataRecette = [];
      this.dataMois = [];

      const recettes = await apiRequest(
        "GET",
        "admin/gain/info",
        undefined,
        false
      );
      if (recettes && recettes.data) {
        var dataGain = recettes.data;

        console.log("dataGain:", dataGain);
        this.dataRecette=[]
        this.dataMois=[]

        dataGain.gains.forEach((element) => {
          if (element.annee == this.annee.value) {
            this.dataRecette.push(element.frais);
            this.dataMois.push(this.mois[element.mois - 1]);
          }
        });

        this.renderChart(
          {
            labels: this.dataMois,
            datasets: [
              {
                label: "Frais de Réservation",
                barPercentage: 0.4,
                backgroundColor: "#3AAA35 ",
                borderColor: "#3AAA35 ",
                borderWidth: 1,
                hoverBackgroundColor: "#3AAA35 ",
                hoverBorderColor: "#3AAA35 ",
                data: this.dataRecette,
              },
            ],
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
            },
          }
        );
      }
    },
  },
};
</script>
